'use client';
import { HEADERS_MAP } from '@/configs/axios';
import { HOME_PAGE, LOGIN_PAGE, SIGNUP_PAGE } from '@/constants/routes.const';
import useService from '@/hooks/useService';
import { AndroidService } from '@/service/Android';
import { AuthService } from '@/service/AuthService';
import { StorageService } from '@/service/StorageService';
import { UserService } from '@/service/User';
import { toast } from '@/utils';
import { Box } from '@radix-ui/themes';
import Button from '@/components/ui/Button';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { useRouter, useSearchParams } from 'next/navigation';
import GoogleIcon from 'public/assets/icons/google.svg';
import LoginIll from 'public/assets/images/login-ill.svg';
import Logo from 'public/assets/logo/logo.svg';
import { useEffect, useState } from 'react';
import { User } from '@/model/Users';
import { LANGUAGES_CONTENT } from '@/constants/language';
import { USER_LOGIN, USER_LOGIN_GOOGLE } from '@/constants/logs';

export default function Page() {
  const router = useRouter();
  const params = useSearchParams();
  const redirectionReason = params.get('redirection_reason');

  useEffect(() => {
    if (StorageService.getAccessToken()) {
      router.replace(HOME_PAGE);
    }
  }, []);

  const CONTENT = LANGUAGES_CONTENT[StorageService.getLanguage()]['login'];

  useEffect(() => {
    if (redirectionReason === 'banned') {
      dispatchEvent(new Event('banned'));
      router.replace(LOGIN_PAGE);
    }
  }, [redirectionReason]);

  return (
    <div className="p-4">
      <div className="flex items-center justify-center my-4">
        <Logo className="text-[80px]" />
      </div>
      <p className="mb-4 text-2xl font-semibold text-center">{CONTENT.title}</p>

      <div className="">
        <LoginIll className="w-full" />
      </div>
      <div className="flex items-center justify-center w-full h-60">
        <GoogleLoginBtn />
      </div>
    </div>
  );
}

function GoogleLoginBtn() {
  const router = useRouter();
  const { call: handleGooglelogin } = useService(
    AuthService.handleGooglelogin,
    [],
    null,
    {
      errorMsg: true,
    }
  );
  const { call: getCountyCode } = useService(UserService.getCountyCode);
  const [isLoading, setIsLoading] = useState(false);

  async function loginSuccess(data) {
    try {
      const res = await handleGooglelogin(data.id_token);
      const profileId = AndroidService.getProfileId()
      if (res.new_user) {
        const path = await getSignupPayload(data);
        router.push(SIGNUP_PAGE + path);
        return;
      }
      const countryCode = await getCountyCode();
      User.setCredentials({
        access_token: res.tokens.access_token,
        refresh_token: res.tokens.refresh_token,
        wid: res.wid,
        countryCode,
        userId: res.user?.user_id,
      });
      AndroidService.logEvent(USER_LOGIN, JSON.stringify({
        userId: res.user?.user_id,
        profile_id: profileId
      }))
      router.replace(HOME_PAGE);
      // setIsLoading(false);
    } catch (err) {
      AuthService.logout();
      setIsLoading(false);
    }
  }

  const CONTENT = LANGUAGES_CONTENT[StorageService.getLanguage()]['login'];
  const TOAST_MSG = LANGUAGES_CONTENT[StorageService.getLanguage()]['toast'];

  return (
    <div className="flex items-center justify-center w-full h-60">
      <Button
        size="4"
        variant="solid"
        className="w-full"
        disabled={isLoading}
        isLoading={isLoading}
        onClick={() => {
          setIsLoading(true);
          AndroidService.signInGoogle()
            .then(({ success, data }) => {
              const parsedData = JSON.parse(data);
              if (!success) {
                toast(TOAST_MSG.loginfailed);
                return;
              }
              loginSuccess(parsedData);
            })
            .catch(err => {
              setIsLoading(false);
            });
        }}
      >
        <>
          <GoogleIcon className="text-2xl" /> {CONTENT.cta}
        </>
      </Button>
    </div>
  );
}

async function getSignupPayload(data) {
  let utmSource = await AndroidService.getInstallReferrerData();
  return jsonToQueryString({
    code: data.id_token,
    utmSource,
    fullName: data.display_name,
  });
}

function jsonToQueryString(json) {
  return (
    '?' +
    Object.keys(json)
      .map(function (key) {
        return encodeURIComponent(key) + '=' + encodeURIComponent(json[key]);
      })
      .join('&')
  );
}
